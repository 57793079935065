import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { GthGoogleButtonComponent } from './components/google-button/google-button.component';
import { GthSocialButtonComponent } from './components/social-button/social-button.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [GthGoogleButtonComponent],
  declarations: [GthSocialButtonComponent, GthGoogleButtonComponent],
})
export class GthSocialButtonsModule { }

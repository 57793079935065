import { ChangeDetectionStrategy, Component, Input, input, Signal, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export interface LeftImage {
  url: string;
  alt: string;
}

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [RouterModule, NgxSkeletonLoaderModule],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {
  image = signal<LeftImage | undefined>(undefined);
}
